import React from 'react';
import cx from 'classnames';
import styles from './index.module.scss';
import { Menu, Icon } from 'semantic-ui-react';
import { Mode } from '@/types/editor';

export default function EditorMenu({
  id,
  className,
  mode = Mode.GENERAL,
  alt = false,
  onClick,
  onPencilClick,
  onZoomClick,
  onZoomInClick,
  onZoomOutClick,
  onCenterClick,
  onMoveClick,
  onUploadClick,
}) {
  const classes = cx('h2e-editor-menu', styles.className, className);

  return (
    <div
      id={id}
      className={classes}
      onClick={onClick}
    >
      <Menu icon vertical inverted>
        <Menu.Item
          active={mode === Mode.PENCIL}
          onClick={onPencilClick}
        >
          <Icon name="pencil" />
        </Menu.Item>
        <Menu.Item
          active={mode === Mode.ZOOM_IN}
          onClick={onZoomInClick}
        >
          <Icon name="zoom-in" />
        </Menu.Item>
        <Menu.Item
          active={mode === Mode.ZOOM_OUT}
          onClick={onZoomOutClick}
        >
          <Icon name="zoom-out" />
        </Menu.Item>
        <Menu.Item
          onClick={onCenterClick}
        >
          <Icon name="crosshairs" />
        </Menu.Item>
        <Menu.Item
          active={mode === Mode.MOVE}
          onClick={onMoveClick}
        >
          <Icon name="hand paper outline" />
        </Menu.Item>
        {/* TODO: should move this to somewhere other than profile editor */}
        <Menu.Item
          onClick={onUploadClick}
        >
          <Icon name="upload" />
        </Menu.Item>
      </Menu>
    </div>
  );
}
