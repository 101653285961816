import React from 'react';
import { Rect } from '../../Control';
import cx from 'classnames';
import styles from './index.module.scss';

const WIDTH = 8;
const HEIGHT = 8;

export default function ProfilePoint({
  id,
  className,
  actived = false,
  x: centerX = 0,
  y: centerY = 0,
  ...props
}) {
  const classes = cx(
    'h2e-control-point',
    styles.className,
    className,
    { actived },
  );
  const x = centerX - WIDTH / 2;
  const y = centerY - HEIGHT / 2;

  return (
    <Rect
      {...props}
      id={id}
      className={classes}
      x={x}
      y={y}
      width={WIDTH}
      height={HEIGHT}
      strokeWidth={1}
    />
  );
}
