import { useState, useEffect } from 'react';

export default function useKeyboard() {
  const [codeMap, setCodeMap] = useState({});

  useEffect(() => {
    const handleKeyDown = (evt) => {
      const { code } = evt;
      setCodeMap((cm) => ({ ...cm, [code]: true }));
    }
    const handleKeyUp = (evt) => {
      const { code } = evt;
      setCodeMap((cm) => ({ ...cm, [code]: false }));
    }
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keyup', handleKeyUp);
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return codeMap;
}
