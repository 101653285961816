export interface PointXY {
  x: number;
  y: number;
}

export type Point = [number, number];

export function copy([x, y]: Point): Point {
  return [x, y];
}

const NEAR = 0.00001;

export function nearXY(pt1: PointXY, pt2: PointXY) {
  return (
    Math.abs(pt1.x - pt2.x) < NEAR &&
    Math.abs(pt1.y - pt2.y) < NEAR
  );
}

export function near([x1, y1], [x2, y2]) {
  return (
    Math.abs(x1 - x2) < NEAR &&
    Math.abs(y1 - y2) < NEAR
  );
}
