import * as P from './point';
import * as B from './bounding-box';
import * as G from './geometry';

export interface CRS {
  type: 'name';
  properties: {
    name: string,
  };
}

export interface Feature {
  type: 'Feature';
  id?: string;
  properties: {
    id?: string,
    ci?: string,
    profile: P.Point[],
  };
  geometry: G.Geometry;
  crs?: CRS;
}

export const empty: Feature = {
  type: 'Feature',
  properties: {
    id: '',
    profile: [],
  },
  geometry: {
    type: 'Point',
    coordinates: [0, 0],
  },
}

export const copy = (feature: Feature): Feature => ({
  ...feature,
  properties: {
    ...feature.properties,
    profile: feature.properties.profile.map(P.copy),
  },
});

export const toLatLng = ({ geometry, ...rest }: Feature): Feature => ({
  ...rest,
  geometry: G.toLatLng(geometry),
});

export const getOriginalCoordinates = ({ geometry }: Feature = empty): P.Point => {
  switch (geometry.type) {
    case 'Point': return geometry.coordinates;
    default: return [0, 0];
  }
};

export const getId = (feature: Feature): string | undefined =>
  (feature && feature.id) || (feature && feature.properties && feature.properties.id);

export const getReach = (feature: Feature): string | undefined =>
  (feature && feature.properties && feature.properties.ci);

export const getProfile = (feature: Feature): P.Point[] => (feature && feature.properties && feature.properties.profile) || [];

export const mapProfile = (feature: Feature, f: (pts: P.Point[]) => P.Point[]): Feature => {
  return {
    ...feature,
    properties: {
      ...feature.properties,
      profile: f(getProfile(feature)),
    },
  };
};

export const getBoundingBox = (feature: Feature) => B.compute(getProfile(feature));

export const hasId = (feature: Feature, id: string): boolean => getId(feature) === id;

export const isNear = (feature: Feature, pt: P.Point): boolean => G.isNear(feature.geometry, pt);
