import React from 'react';
import styles from './index.module.scss';

export default function DEMPath({ data = [] }) {
  if (data.length === 0) return null;

  const { x, y } = data[0];
  let d = `M ${x} ${y} `;
  for (let i = 1; i < data.length; ++i) {
    const { x, y } = data[i];
    d += `L ${x} ${y} `;
  }

  return (
    <path className={styles.className} d={d} />
  );
}
