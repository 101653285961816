import React, { useState, useEffect, useRef } from 'react';
import { useFile } from '@caasi/hooks';
import cx from 'classnames';
import styles from './index.module.scss';

const SPREADSHEET_MIMETYPES =
  '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel';

export default function ({
  id,
  className,
  onChange,
}) {
  const classes = cx('h2e-data-uploader', styles.className, className);
  const handlerRef = useRef();
  handlerRef.current = onChange;
  const [crossSectionFile, setCrossSectionFile] = useState();
  const [streamNetworkFile, setStreamNetworkFile] = useState();
  const [sectionCoordinates, setSectionCoordinates] = useState();
  const [sectionData, setSectionData] = useState();
  const crossSection = useFile(crossSectionFile, 'text');
  const streamNetwork = useFile(streamNetworkFile, 'text');

  useEffect(() => {
    const onChange = handlerRef.current;
    if (typeof onChange !== 'function') return;
    if (!crossSection) return;
    if (!streamNetwork) return;
    if (!sectionCoordinates) return;
    if (!sectionData) return;
    onChange({
      crossSection: JSON.parse(crossSection),
      streamNetwork: JSON.parse(streamNetwork),
    });
  }, [crossSection, streamNetwork, sectionCoordinates, sectionData]);

  return (
    <form id={id} className={classes}>
      <div className={styles.item}>
        <label htmlFor="cross-section">Cross Section</label>
        <input
          id="cross-section"
          type="file"
          accept="application/json"
          onChange={(evt) => {
            const { target: { files = [] } } = evt;
            if (files.length === 0) return;
            setCrossSectionFile(files[0]);
          }}
        />
      </div>
      <div className={styles.item}>
        <label htmlFor="stream-network">Stream Network</label>
        <input
          id="stream-network"
          type="file"
          accept="application/json"
          onChange={(evt) => {
            const { target: { files = [] } } = evt;
            if (files.length === 0) return;
            setStreamNetworkFile(files[0]);
          }}
        />
      </div>
      <div className={styles.item}>
        <label htmlFor="section-coordinates">斷面座標點</label>
        <input
          id="section-coordinates"
          type="file"
          accept={SPREADSHEET_MIMETYPES}
          onChange={(evt) => {
            const { target: { files = [] } } = evt;
            if (files.length === 0) return;
            setSectionCoordinates(files[0]);
          }}
        />
      </div>
      <div className={styles.item}>
        <label htmlFor="section-data">斷面資料</label>
        <input
          id="section-data"
          type="file"
          accept={SPREADSHEET_MIMETYPES}
          onChange={(evt) => {
            const { target: { files = [] } } = evt;
            if (files.length === 0) return;
            setSectionData(files[0]);
          }}
        />
      </div>
    </form>
  );
}
