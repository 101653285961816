import React, { useCallback, useMemo, useState } from 'react';
import { Modal, Button } from 'semantic-ui-react';
import cx from 'classnames';
import styles from './index.module.scss';
import { useDEM } from '@/hooks';
import * as S from '@/types/selection';
import * as L from '@/types/layer';
import { Feature as F, FeatureCollection as FC } from '@/types/geojson';
import { useUpload } from '@/hooks';
import ProfileEditor, { useProfileEditor } from '@/components/ProfileEditor';
import ProfileMap from '@/components/ProfileMap';
import ProfileDetail from '@/components/ProfileDetail';
import ProfileLayerList from '@/components/ProfileLayerList';
import DEMChart from '@/components/DEMChart';
import DataUploader from '@/components/DataUploader';

export default function Editor({ id, className }) {
  const classes = cx('h2e-editor', styles.className, className);
  const [data, setData] = useState({
    crossSection: undefined,
    streamNetwork: undefined,
  });
  const defaultPoints = useUpload();
  const defaultLayers = useMemo(
    () => defaultPoints.features.map(f => L.initialize(f, 'unknown')),
    [defaultPoints],
  );
  const [isUploadOpen, setUploadOpen] = useState(true);
  const [profileList, setProfileList] = useState(FC.empty);
  const [streamNetwork, setStreamNetwork] = useState(FC.empty);

  const findProfile = useCallback((id) => {
    return profileList.features.find(p => p.properties.id === id);
  }, [profileList]);

  const profileEditor = useProfileEditor();
  const activedLayer = useMemo(
    () => S.getActived(profileEditor.selection),
    [profileEditor.selection],
  );
  const activedId = useMemo(
    () => {
      if (!activedLayer) return '';
      return F.getId(activedLayer.profile);
    },
    [activedLayer],
  );
  const visibilityMap = useMemo(
    () => {
      const list = S.listVisible(profileEditor.selection);
      let ret = {};
      for (let layer of list) {
        ret[L.getId(layer)] = true;
      }
      return ret;
    },
    [profileEditor.selection],
  );
  const reachProfileList = useMemo(
    () => {
      if (!activedLayer) return FC.empty;
      const { profile } = activedLayer;
      const reach = F.getReach(profile);
      return FC.takeByReach(profileList, reach);
    },
    [profileList, activedLayer],
  );
  const demIdList = useMemo(
    () => reachProfileList.features.map(F.getId),
    [reachProfileList],
  );
  const dem = useDEM(demIdList, profileList);

  const handleUpload = useCallback(() => {
    let cs = FC.filter(data.crossSection, x => !!x);
    cs = FC.toLatLng(cs);
    let sn = FC.filter(data.streamNetwork, x => !!x);
    sn = FC.toLatLng(sn);
    setProfileList(cs);
    setStreamNetwork(sn);
    setUploadOpen(false);
    defaultLayers.forEach(layer =>
      profileEditor.selection.push(layer)
    );
  }, [data, profileEditor, defaultLayers]);

  return (
    <div id={id} className={classes}>
      <div className={styles.left}>
        <ProfileEditor
          {...profileEditor}
          className={styles.editor}
          onUploadClick={() => setUploadOpen(true)}
        />
        <DEMChart
          className={styles.dem}
          focused={activedId}
          visiblePoints={visibilityMap}
          data={dem}
          onPointClick={(pt) => {
            const sele = profileEditor.selection;
            const idx = S.findLayerIndexById(sele, pt.label);
            if (idx !== -1) {
              const layer = S.getLayer(sele, idx);
              sele.update(idx, L.updateVisible(layer, !layer.visible))
            } else {
              const profile = FC.findById(profileList, pt.label);
              const layer = L.initialize(profile, 'dem');
              sele.push(layer);
            }
          }}
        />
      </div>
      <div className={styles.right}>
        <ProfileMap
          className={styles.map}
          focused={activedId}
          streamNetwork={streamNetwork}
          profileList={profileList}
          uploadPoints={defaultPoints}
          visiblePoints={visibilityMap}
          onProfileClick={(evt) => {
            if (evt.features.length > 0) {
              const sele = profileEditor.selection;
              const id = F.getId(evt.features[0]);
              const hasId = S.findLayerIndexById(sele, id) !== -1;
              if (hasId) return;
              const profile = findProfile(id);
              const layer = L.initialize(profile);
              sele.push(layer);
            }
          }}
        />
        <ProfileLayerList
          className={styles.layers}
          data={profileEditor.selection}
          onSelect={(i) => profileEditor.selection.active(i)}
          onLayerChange={(i, l) => profileEditor.selection.update(i, l)}
          onLayerRemove={(i) => profileEditor.selection.remove(i)}
        />
        <ProfileDetail
          className={styles.detail}
          data={activedLayer}
        />
      </div>
      <Modal
        size="tiny"
        open={isUploadOpen}
        onClose={() => setUploadOpen(false)}
      >
        <Modal.Header>Data Upload</Modal.Header>
        <Modal.Content>
          <DataUploader onChange={setData} />
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            disabled={!data.crossSection || !data.streamNetwork}
            onClick={handleUpload}
          >
            Submit
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
}
