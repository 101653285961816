const MIN_SCALE = 0.0625;
const MAX_SCALE = 4;

export const emptyCamera = {
  viewBox: {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  },
  width: undefined,
  height: undefined,
};

export function getScale(camera) {
  if (!camera.width) return 1;
  return camera.viewBox.width / camera.width;
}

export function getCenter({ viewBox }) {
  const x = viewBox.x + viewBox.width / 2;
  const y = viewBox.y + viewBox.height / 2;
  return [x, y];
}

export function getDimension({ width, height }) {
  return [width, height];
}

export function getViewBoxDimension({ viewBox }) {
  return [viewBox.width, viewBox.height];
}

export function initialize([width, height]) {
  return {
    viewBox: {
      x: -width / 2,
      y: -height / 2,
      width,
      height,
    },
    width,
    height,
  };
}

export function resize(camera, [width, height]) {
  return {
    viewBox: {
      ...camera.viewBox,
      width,
      height,
    },
    width,
    height,
  };
}

export function translate(camera, [x, y]) {
  return {
    ...camera,
    viewBox: {
      ...camera.viewBox,
      x,
      y,
    },
  };
}

export function move(camera, [x, y]) {
  return translate(camera, [camera.viewBox.x + x, camera.viewBox.y + y]);
}

export function scale(camera, scale, [anchorX = 0, anchorY = 0]) {
  if (scale < MIN_SCALE) scale = MIN_SCALE;
  if (scale > MAX_SCALE) scale = MAX_SCALE;
  const prevScale = getScale(camera);
  const offsetX = (camera.viewBox.x - anchorX) / prevScale * scale;
  const offsetY = (camera.viewBox.y - anchorY) / prevScale * scale;
  const x = anchorX + offsetX;
  const y = anchorY + offsetY;
  const width = camera.width * scale;
  const height = camera.height * scale;
  return {
    ...camera,
    viewBox: { x, y, width, height },
  };
}
