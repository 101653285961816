import React, { useState, useEffect, useCallback, useMemo } from 'react';
import cx from 'classnames';
import styles from './index.module.scss';
import ResizeDetector from 'react-resize-detector';
import EditorMenu from './EditorMenu';
import Stage from './Stage';
import CoordinateGrid from './CoordinateGrid';
import * as Profile from './Profile';
import { useKeyboard, useSelection } from '@/hooks';
import * as A from '@/types/array';
import * as S from '@/types/selection';
import * as PP from '@/types/profile-points';
import { Mode, GRID_WIDTH, GRID_HEIGHT, SCALE_FACTOR, WHEEL_SCALE_FACTOR } from '@/types/editor';
import * as C from '@/types/camera';
import * as L from '@/types/line';
import * as LY from '@/types/layer';
import { Feature as F } from '@/types/geojson';
import * as B from '@/types/bounding-box';

export function useProfileEditor() {
  const [mode, setMode] = useState(Mode.GENERAL);
  const [camera, setCamera] = useState(C.emptyCamera);
  const selection = useSelection(S.empty);
  const { profile, point, setProfile, setPoint } = selection;
  const keyboard = useKeyboard();
  const del = useMemo(() => keyboard.Backspace || keyboard.Delete, [keyboard]);
  const esc = keyboard.Escape;
  const visibleLayers = useMemo(
    () => selection.selections.filter(LY.isVisible),
    [selection.selections],
  );
  // the outmost bounding box
  const boundingBox = useMemo(
    () => visibleLayers
      .map(({ profile }) => F.getBoundingBox(profile))
      .reduce((acc, bbox) => B.concat(acc, bbox), B.empty),
    [visibleLayers],
  );

  const focus = useCallback(
    () => setCamera((c) => {
      if (B.isEmpty(boundingBox)) return c;

      const [cx, cy] = B.getCenter(boundingBox);
      let [bw, bh] = B.getDimension(boundingBox);

      // expand the current bounding box
      const padding = (bw > bh ? bw : bh) / 10;
      const bbox = B.expand(boundingBox, padding);

      // move camera to the center of current bounding box
      const [vw, vh] = C.getViewBoxDimension(c);
      const c1 = C.translate(c, [cx - vw / 2, cy - vh / 2]);

      // scale to fit the expanded bounding box
      const [w, h] = C.getDimension(c);
      [bw, bh] = B.getDimension(bbox);
      const sx = bw / w;
      const sy = bh / h;
      const s = sx > sy ? sx : sy;
      const c2 = C.scale(c1, s, [cx, cy]);

      return c2;
    }),
    [boundingBox],
  );

  useEffect(() => {
    if (!del) return;
    if (mode !== Mode.PENCIL) return;
    if (point === undefined) return;
    setProfile(A.remove(profile, point));
    setPoint();
  }, [setProfile, setPoint, del, mode, point, profile]);

  useEffect(() => {
    if (!esc) return;
    setMode(Mode.GENERAL);
  }, [esc]);

  return {
    mode: keyboard.Space ? Mode.MOVE : mode,
    alt: keyboard.AltLeft || keyboard.AltRight,
    camera,
    selection,
    visibleLayers,
    setMode,
    setCamera,
    focus,
  };
}

export default function ProfileEditor({
  id,
  className,
  mode = Mode.GENERAL,
  alt,
  camera = C.emptyCamera,
  selection = S.empty,
  visibleLayers = [],
  setMode,
  setCamera,
  focus,
  onUploadClick,
}) {
  const classes = cx('h2e-profile-editor', styles.className, className);
  const { profile } = selection;
  const isDraggable = mode === Mode.MOVE;
  const showBoundingBox = mode === Mode.GENERAL && profile && profile.length !== 0;

  // change this if you want to change cursor by mode
  const stageStyle = { cursor: undefined };

  // do nothing for now
  const handleClick = useCallback(
    (evt, pt) => { },
    [],
  );
  // handle wheel zooming in the move mode
  const handleWheel = useCallback(
    (evt, pt) => {
      switch (mode) {
        case Mode.MOVE: {
          const { deltaY } = evt;
          setCamera((c) => {
            const scale = C.getScale(c) * (1.0 + WHEEL_SCALE_FACTOR * deltaY);
            return C.scale(c, scale, pt);
          });
          break;
        }
        default:
      }
    },
    [setCamera, mode],
  );
  const handleDragMove = useCallback(
    (evt, pt, [offsetX, offsetY]) => {
      setCamera((c) => C.move(c, [-offsetX, -offsetY]));
    },
    [setCamera],
  );

  return (
    <div id={id} className={classes}>
      <EditorMenu
        className={styles.menu}
        mode={mode}
        alt={alt}
        onClick={() => {
          setMode(Mode.GENERAL);
        }}
        onPencilClick={(evt) => {
          evt.stopPropagation();
          switch (mode) {
            case Mode.PENCIL:
              return setMode(Mode.GENERAL);
            default:
              return setMode(Mode.PENCIL);
          }
        }}
        onZoomInClick={(evt) => {
          evt.stopPropagation();
          setCamera((c) => {
            const scale = C.getScale(c) * SCALE_FACTOR;
            const center = C.getCenter(c);
            return C.scale(c, scale, center);
          });
        }}
        onZoomOutClick={(evt) => {
          evt.stopPropagation();
          setCamera((c) => {
            const scale = C.getScale(c) / SCALE_FACTOR;
            const center = C.getCenter(c);
            return C.scale(c, scale, center);
          });
        }}
        onCenterClick={(evt) => {
          evt.stopPropagation();
          focus();
        }}
        onMoveClick={(evt) => {
          evt.stopPropagation();
          switch (mode) {
            case Mode.MOVE:
              return setMode(Mode.GENERAL);
            default:
              return setMode(Mode.MOVE);
          }
        }}
        onUploadClick={onUploadClick}
      />
      <div className={styles.content}>
        <div className={styles.layer}>
          <Stage
            style={stageStyle}
            draggable={isDraggable}
            camera={camera}
            onClick={handleClick}
            onWheel={handleWheel}
            onDragMove={handleDragMove}
          >
            <CoordinateGrid gridWidth={GRID_WIDTH} gridHeight={GRID_HEIGHT} />
            {visibleLayers.map(({ profile } = {}, i) =>
              <Profile.Path key={F.getId(profile)} data={F.getProfile(profile)} color={'#666'} />
            )}
            <Profile.Path
              data={profile}
              color={'#2980b9'}
              onSegmentClick={(evt, pt, line) => {
                if (mode !== Mode.PENCIL) return;
                const [[x1, y1]] = line;
                const i = profile.findIndex(([x2, y2]) => x1 === x2 && y1 === y2);
                const newPt = L.getProjectedPoint(line, pt);
                selection.insertProfile(i, newPt);
                selection.setPoint(i + 1);
              }}
            />
            <g className={styles.control}>
              {mode === Mode.PENCIL && profile.map(([x, y], i) =>
                <Profile.Point
                  key={i}
                  actived={i === selection.point}
                  x={x}
                  y={y}
                  onClick={() => {
                    selection.setPoint(i);
                  }}
                  onDragStart={() => {
                    selection.setPoint(i);
                  }}
                  onDragMove={(evt, pt, offset) => {
                    pt = PP.guardByAdjacentPoints(profile, i, pt);
                    selection.setProfile(A.update(profile, i, pt));
                  }}
                />
              )}
              {selection.point !== undefined &&
                <Profile.Coordinate pt={profile[selection.point]} />
              }
              {showBoundingBox &&
                <Profile.BoundingBox
                  data={profile}
                  onClick={() => {
                    setMode(Mode.PENCIL);
                    selection.setPoint();
                  }}
                />
              }
            </g>
          </Stage>
          <ResizeDetector
            handleWidth
            handleHeight
            onResize={(width, height) => {
              if (camera.width === undefined || camera.height === undefined) {
                setCamera(C.initialize([width, height]));
              } else {
                setCamera(C.resize(camera, [width, height]));
              }
            }}
          />
        </div>
        <div className={cx(styles.layer, styles.debug)}>
          <p>editor mode: {mode}</p>
        </div>
      </div>
    </div>
  );
}
