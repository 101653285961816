import React, { useState, useMemo } from 'react';
import cx from 'classnames';
import styles from './index.module.scss';
import * as DEM from '@/types/dem-profile.ts';
import DEMPath from './DEMPath';
import DEMPoint from './DEMPoint';
import ResizeDetector from 'react-resize-detector';

function useDEMChart() {
  const [dimension, setDimension] = useState({ width: 0, height: 0 });

  return {
    padding: 12,
    ...dimension,
    setDimension,
  };
}

export default function DEMChart({
  id,
  className,
  loading = false,
  focused,
  actived,
  visiblePoints = {},
  data,
  onPointClick,
}) {
  const classes = cx('h2e-dem-chart', styles.className, className);
  const state = useDEMChart();
  const points = useMemo(() => DEM.layout(data, state), [data, state]);
  const labeled = useMemo(() => points.filter(pt => pt.label), [points]);

  if (DEM.isEmpty(data)) {
    return (
      <div id={id} className={cx(classes, styles.empty)}>
        <span>no data</span>
      </div>
    );
  }

  return (
    <div id={id} className={classes}>
      <svg
        xmlns="http://www.w3c.org/2000/svg"
        className={styles.chart}
        width={state.width}
        height={state.height}
        viewBox={`0 0 ${state.width} ${state.height}`}
      >
        <DEMPath data={points} />
        {labeled.map(pt =>
          <DEMPoint
            key={pt.label}
            primary={focused === pt.label}
            actived={visiblePoints[pt.label]}
            data={pt}
            onClick={() => {
              if (typeof onPointClick === 'function') {
                onPointClick(pt);
              }
            }}
          />
        )}
      </svg>
      <ResizeDetector
        handleWidth
        handleHeight
        onResize={(width, height) => state.setDimension({ width, height })}
      />
    </div>
  );
}
