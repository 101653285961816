export function insert(as, i, v) {
  return [
    ...as.slice(0, i + 1),
    v,
    ...as.slice(i + 1),
  ];
}

export function update(as, i, v) {
  return [
    ...as.slice(0, i),
    v,
    ...as.slice(i + 1),
  ];
}

export function remove(as, i) {
  return [
    ...as.slice(0, i),
    ...as.slice(i + 1),
  ];
}
