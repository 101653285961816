import React, { useState, useEffect, useCallback, useMemo } from 'react';
import cx from 'classnames';
import styles from './index.module.scss';
import MapGL, { Source, Layer, NavigationControl } from 'react-map-gl';
import { FeatureCollection as FC } from '@/types/geojson';
import * as F from '@/types/feature';

const zoom = 12;

export default function ProfileMap({
  id,
  className,
  focused,
  streamNetwork = FC.empty,
  profileList = FC.empty,
  uploadPoints = FC.empty,
  visiblePoints = {},
  onProfileClick,
}) {
  const classes = cx('h2e-profile-map', styles.className, className);
  const layerId = 'profile-list';
  const focusedList = useMemo(
    () => FC.filter(profileList, f => F.hasId(f, focused)),
    [profileList, focused],
  );
  const visibleList = useMemo(
    () => FC.filter(profileList, f => visiblePoints[F.getId(f)]),
    [profileList, visiblePoints],
  );
  const [longitude, latitude] = [121.77230649183059, 24.71550354912198];
  const [viewport, setViewport] = useState({ latitude, longitude, zoom });
  const handleClick = useCallback((evt) => {
    if (!evt.features) return;

    const features =
      evt.features.filter(f => f.layer && f.layer.id === layerId);
    if (features.length > 0 && typeof onProfileClick === 'function') {
      onProfileClick({ features });
    }
  }, [onProfileClick]);

  useEffect(() => {
    setViewport({ latitude, longitude, zoom });
  }, [latitude, longitude]);

  return (
    <div id={id} className={classes}>
      <MapGL
        {...viewport}
        width="100%"
        height="100%"
        onViewportChange={(viewport) => setViewport(viewport)}
        onClick={handleClick}
      >
        <Source
          id="stream-network"
          type="geojson"
          data={streamNetwork}
        >
          <Layer
            id={'stream-network'}
            type="line"
            layout={{ 'line-join': 'round', 'line-cap': 'round' }}
            paint={{ 'line-color': '#3498db', 'line-width': 3 }}
          />
        </Source>
        <Source
          id="visible-source"
          type="geojson"
          data={visibleList}
        >
          <Layer
            id="visible-layer"
            type="circle"
            paint={{
              'circle-color': 'transparent',
              'circle-stroke-width': 2,
              'circle-stroke-color': '#4980B6',
              'circle-radius': 7,
            }}
          />
        </Source>
        <Source
          id="profile-source"
          type="geojson"
          data={profileList}
        >
          <Layer
            id={layerId}
            type="circle"
            paint={{
              'circle-color': '#4980B6',
              'circle-radius': 5,
            }}
          />
        </Source>
        <Source
          id="upload-source"
          type="geojson"
          data={uploadPoints}
        >
          <Layer
            id="upload-layer"
            type="circle"
            paint={{
              'circle-color': '#00ff00',
              'circle-radius': 5,
            }}
          />
        </Source>
        <Source
          id="focused-source"
          type="geojson"
          data={focusedList}
        >
          <Layer
            id="focused-layer"
            type="circle"
            paint={{
              'circle-color': '#ff0000',
              'circle-radius': 5,
            }}
          />
        </Source>
        <div className={styles.navigation}>
          <NavigationControl />
        </div>
      </MapGL>
    </div>
  );
}
