import { useMemo } from 'react';
import { usePromise } from '@caasi/hooks';
import { Feature, FeatureCollection, DEMProfile } from '@/types/geojson';
import { demProfile } from '@/api/gis';

function patchDEMCoordinates(dem, fc) {
  const { coordinates = [], ...rest } = dem;
  return {
    ...rest,
    coordinates: coordinates.map((coord) => {
      const { features = [] } =
        FeatureCollection.findByNearPoint(fc, [coord.x, coord.y]);
      if (!features.length) return coord;
      const [f] = features;
      return { ...coord, label: Feature.getId(f) };
    }),
  };
}

// TODO: search DEM profile by ids
export default function useDEM(ids = [], profileList = FeatureCollection.empty) {
  const coords = useMemo(
    () => ids
      .map(id => FeatureCollection.findById(profileList, id))
      .filter(x => !!x)
      .map(Feature.getOriginalCoordinates),
    [ids, profileList],
  );
  const [profile] = usePromise(useMemo(
    () => {
      if (coords.length === 0) return Promise.resolve(DEMProfile.empty);
      return demProfile(coords);
    },
    [coords],
  ));

  return useMemo(
    () => {
      if (!profile) return DEMProfile.empty;
      const patched = patchDEMCoordinates(DEMProfile.toLatLng(profile), profileList);
      return patched;
    },
    [profile, profileList],
  );
}
