import axios from 'axios';
import { GIS_SERVICE } from '@/consts';

export function demProfile(coordinates = []) {
  return axios
    .post(
      `${GIS_SERVICE}/dem/profile`,
      {
        type: 'LineString',
        coordinates,
      }
    )
    .then(({ data }) => data);
}
