import React from 'react';
import cx from 'classnames';
import styles from './index.module.scss';
import * as B from '@/types/bounding-box';

export default function BoundingBox({
  id,
  className,
  data = [],
  onClick,
}) {
  const classes = cx('h2e-bounding-box', styles.className, className);
  const [left, top, right, bottom] = B.compute(data);
  const width = right - left;
  const height = bottom - top;

  return (
    <rect
      id={id}
      className={classes}
      x={left}
      y={top}
      width={width}
      height={height}
      onClick={onClick}
    />
  );
}
