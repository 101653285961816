export const empty = [
  Infinity,
  Infinity,
  -Infinity,
  -Infinity,
];

export function isEmpty([left, top, right, bottom]) {
  return (
    left === empty[0] &&
    top === empty[1] &&
    right === empty[2] &&
    bottom === empty[3]
  );
}

export function compute(ps, bbox = empty) {
  if (!ps || ps.length === 0) return bbox;

  let [left, top, right, bottom] = bbox;
  for (let p of ps) {
    const [x, y] = p;
    if (x < left) left = x;
    if (y < top) top = y;
    if (x > right) right = x;
    if (y > bottom) bottom = y;
  }

  return [left, top, right, bottom];
}

export function concat(
  [left1, top1, right1, bottom1] = empty,
  [left2, top2, right2, bottom2] = empty,
) {
  const left = left1 < left2 ? left1 : left2;
  const top = top1 < top2 ? top1 : top2;
  const right = right1 > right2 ? right1 : right2;
  const bottom = bottom1 > bottom2 ? bottom1 : bottom2;

  return [left, top, right, bottom];
}

export function getCenter([left, top, right, bottom]) {
  const x = (left + right) / 2;
  const y = (top + bottom) / 2;
  return [x, y];
}

export function getDimension([left, top, right, bottom]) {
  return [right - left, bottom - top];
}

export function expand([left, top, right, bottom] = empty, padding = 0) {
  return [left - padding, top - padding, right + padding, bottom + padding];
}
