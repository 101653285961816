import React, { useContext } from 'react';
import { StageContext } from '@/components/ProfileEditor/Stage';

export default function Path({ strokeWidth = 1, onClick, ...props }) {
  const { stageFromScreen, unscale } = useContext(StageContext);
  const [sw] = unscale([strokeWidth, 0]);

  return (
    <path
      {...props}
      strokeWidth={sw}
      onClick={(evt) => {
        const pt = stageFromScreen([evt.clientX, evt.clientY]);
        if (typeof onClick === 'function') {
          onClick(evt, pt);
        }
      }}
    />
  );
}
