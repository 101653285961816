import React, { Component, createContext } from 'react';
import cx from 'classnames';
import styles from './index.module.scss';
import * as C from '@/types/camera';

const identity = x => x;

export const StageContext = createContext({
  viewBox: C.emptyCamera.viewBox,
  stageFromScreen: identity,
  unscale: identity,
});

class Stage extends Component {
  constructor(props) {
    super(props);
    this.svg = undefined;
    this.pt = undefined;
    this.startPt = undefined;
    this.state = {
      isDragging: false,
    };
  }

  stageFromScreen = ([x = 0, y = 0]) => {
    if (!this.svg) return [0, 0];
    this.pt.x = x;
    this.pt.y = y;
    const pt = this.pt.matrixTransform(this.svg.getScreenCTM().inverse());
    return [pt.x, pt.y];
  }

  unscale = ([x = 0, y = 0]) => {
    const { camera } = this.props;
    const scale = C.getScale(camera);
    return [x * scale, y * scale];
  }

  handleClick = (evt) => {
    const { draggable, onClick } = this.props;
    if (!draggable && typeof onClick === 'function') {
      const pt = this.stageFromScreen([evt.clientX, evt.clientY]);
      onClick(evt, pt);
    }
  }

  handleWheel = (evt) => {
    const { onWheel } = this.props;
    if (typeof onWheel === 'function') {
      const pt = this.stageFromScreen([evt.clientX, evt.clientY]);
      onWheel(evt, pt);
    }
  }

  handleMouseDown = (evt) => {
    if (!this.props.draggable) return;
    this.startPt = this.stageFromScreen([evt.clientX, evt.clientY]);
    window.addEventListener('mousemove', this.handleDragMove);
    window.addEventListener('mouseup', this.handleDragEnd);
  }

  handleDragMove = (evt) => {
    const { onDragStart, onDragMove } = this.props;
    const { isDragging } = this.state;
    const pt = this.stageFromScreen([evt.clientX, evt.clientY]);
    if (!isDragging) {
      this.setState({ isDragging: true });
      if (typeof onDragStart === 'function') {
        onDragStart(evt, pt);
      }
    }
    const [startX, startY] = this.startPt;
    const [stageX, stageY] = pt;
    const offset = [
      stageX - startX,
      stageY - startY,
    ];
    if (typeof onDragMove === 'function') {
      onDragMove(evt, pt, offset);
    }
  }

  handleDragEnd = (evt) => {
    const { onDragEnd, onClick } = this.props;
    const { isDragging } = this.state;
    const pt = this.stageFromScreen([evt.clientX, evt.clientY]);
    if (!isDragging && typeof onClick === 'function') {
      onClick(evt, pt);
    }
    if (isDragging && typeof onDargEnd === 'function') {
      onDragEnd(evt, pt);
    }
    this.setState({ isDragging: false });
    window.removeEventListener('mousemove', this.handleDragMove);
    window.removeEventListener('mouseup', this.handleDragEnd);
  }

  render() {
    const {
      id,
      className,
      draggable = false,
      camera,
      children,
      onClick,
      onWheel,
      onDragStart,
      onDragMove,
      onDragEnd,
      ...rest
    } = this.props;
    const { isDragging: dragging } = this.state;
    const classes = cx(
      'h2e-editor-stage',
      styles.className,
      className,
      { draggable, dragging },
    );
    const { viewBox } = camera;
    const context = {
      viewBox,
      stageFromScreen: this.stageFromScreen,
      unscale: this.unscale,
    };

    return (
      <svg
        xmlns="http://www.w3c.org/2000/svg"
        {...rest}
        id={id}
        className={classes}
        ref={elem => {
          if (!elem) return;
          this.svg = elem;
          this.pt = elem.createSVGPoint();
        }}
        width={camera.width}
        height={camera.height}
        viewBox={`${viewBox.x} ${viewBox.y} ${viewBox.width} ${viewBox.height}`}
        onClick={this.handleClick}
        onWheel={this.handleWheel}
        onMouseDown={this.handleMouseDown}
      >
        <StageContext.Provider value={context}>
          {children}
        </StageContext.Provider>
      </svg>
    );
  }
}

export default Stage;
