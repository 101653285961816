import { coordsToLatLng } from './proj4';
import { Layout } from './svg';
import { nearXY } from './point';
import { FeatureCollection } from './feature-collection';

export interface Coordinate {
  x: number;
  y: number;
  label?: string;
}

export interface Profile {
  x: number;
  y: number;
  dy: number;
  z: number;
}

export interface DEMProfile {
  coordinates: Coordinate[];
  profile: Profile[];
}

export const empty: DEMProfile = {
  coordinates: [],
  profile: [],
};

export const isEmpty = ({ coordinates = [], profile = [] }: DEMProfile): boolean =>
  coordinates.length === 0 && profile.length === 0;

export const toLatLng = ({ coordinates = [], profile = [] }: DEMProfile): DEMProfile => ({
  coordinates: coordinates
    .map(({ x: lng = 0, y: lat = 0, ...rest }: any) => {
      const [x, y] = coordsToLatLng([lng, lat]);
      return { x, y, ...rest };
    }),
  profile: profile
    .map(({ x: lng = 0, y: lat = 0, ...rest }: any) => {
      const [x, y] = coordsToLatLng([lng, lat]);
      return { x, y, ...rest };
    }),
});

export const toFeatureCollection = ({ coordinates = [] }: DEMProfile): FeatureCollection => ({
  type: 'FeatureCollection',
  features: [{
    type: 'Feature',
    properties: {
      id: 'dem-profile-line',
      profile: [],
    },
    geometry: {
      type: 'LineString',
      coordinates: coordinates.map(({ x, y }) => [x, y]),
    },
  }],
});

export function layout(data: DEMProfile, layout: Layout): Coordinate[] {
  const { coordinates = [], profile = [] } = data;
  const { padding, width: fullWidth, height: fullHeight } = layout;
  if (profile.length === 0) return [];

  const width = fullWidth - 2 * padding;
  const height = fullHeight - 2 * padding;

  let zMin = Infinity;
  let zMax = -Infinity;
  let yMax = -Infinity;
  for (let p of profile) {
    if (p.z < zMin) zMin = p.z;
    if (p.z > zMax) zMax = p.z;
    if (p.dy > yMax) yMax = p.dy;
  }
  const dz = zMax - zMin;

  if (yMax === 0 || dz === 0) return [];

  let result: Coordinate[] = [];
  for (let pt of profile) {
    let { dy, z: z1 } = pt;
    let label;
    for (let i = 0; i < coordinates.length; ++i) {
      const coord = coordinates[i];
      if (nearXY(pt, coord)) {
        label = coord.label;
      }
    }
    result.push({
      label,
      x: dy / yMax * width + padding,
      y: (z1 - zMin) / dz * height + padding,
    });
  }

  return result;
}
