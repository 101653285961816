import proj4 from 'proj4';

proj4.defs([
  [
    'EPSG:4326',
    '+title=WGS84 (long/lat) +proj=longlat +ellps=WGS84 +datum=WGS84 +units=degrees',
  ],
  [
    'EPSG:3826',
    '+title=TWD97 TM2 +proj=tmerc +lat_0=0 +lon_0=121 +k=0.9999 +x_0=250000 +y_0=0 +ellps=GRS80 +units=m +no_defs',
  ],
  [
    'EPSG:3828',
    '+title=TWD67 TM2 +proj=tmerc +lat_0=0 +lon_0=121 +k=0.9999 +x_0=250000 +y_0=0 +ellps=aust_SA +towgs84=-752,-358,-179,-0.0000011698,0.0000018398,0.0000009822,0.00002329 +units=m +no_defs',
  ],
]);

const proj = {
  EPSG3826: new proj4.Proj('EPSG:3826'),
  EPSG3828: new proj4.Proj('EPSG:3828'),
  EPSG4326: new proj4.Proj('EPSG:4326'),
};

export function coordsToLatLng(coords) {
  return proj4(proj.EPSG3826, proj.EPSG4326, coords);
}
