import React from 'react';
import cx from 'classnames';
import styles from './index.module.scss';
import * as PP from '@/types/profile-points';
import { Feature as F } from '@/types/geojson';
import numeral from 'numeral';

// XXX: duplicated
const COORDINATE_FORMAT = '0.00';

export default function ProfileDetail({ id, className, data }) {
  const classes = cx('h2e-profile-detail', styles.className, className);
  const p = (data || {}).profile;
  const { properties, geometry } = p || {};
  const profile = F.getProfile(p);

  return (
    <div id={id} className={classes}>
      {!data
        ? <span>select a profile from the map</span>
        : (
          <ul>
            {properties &&
              <li>id: <span>{properties.id}</span></li>
            }
            {geometry &&
              <li>geometry: <span>{geometry.coordinates.join(', ')}</span></li>
            }
            {profile && profile.length !== 0 &&
              <li>
                profile:
                <ol>
                  {profile.map((pt, i) => {
                    const [x, y] = PP.mapFromScreen(pt);
                    const displayX = numeral(x).format(COORDINATE_FORMAT);
                    const displayY = numeral(y).format(COORDINATE_FORMAT);
                    return <li key={i}>({displayX}, {displayY})</li>;
                  })}
                </ol>
              </li>
            }
          </ul>
        )
      }
    </div>
  );
}
