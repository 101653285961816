import { useState, useCallback, useMemo, useEffect } from 'react';
import * as S from '@/types/selection';
import * as PP from '@/types/profile-points';
import * as A from '@/types/array';
import * as L from '@/types/layer';
import { Feature as F } from '@/types/geojson';

export default function useSelection(init = S.empty) {
  const [selection, setSelection] = useState(init);
  const [point, setPoint] = useState();

  const active = useCallback((i) => {
    setPoint();
    setSelection((ss) => S.active(ss, i));
  }, []);

  const push = useCallback((l) => {
    setSelection((ss) => {
      const profile = F.mapProfile(
        l.profile,
        pts => pts.map(PP.mapToScreen).sort(PP.sortByX),
      );
      const layer = L.updateProfile(l, profile);
      return S.push(ss, layer);
    });
  }, []);

  const update = useCallback((i, l) => {
    setSelection((ss) => S.update(ss, i, l));
  }, []);

  const remove = useCallback((i) => {
    setSelection((ss) => S.remove(ss, i));
  }, []);

  const profile = useMemo(() => {
    const layer = S.getActived(selection);
    return (layer && layer.profile && F.getProfile(layer.profile)) || [];
  }, [selection]);

  const setProfile = useCallback(
    (ps) =>
      setSelection((ss) => {
        const l = S.getActived(ss);
        const layer = L.updateProfile(l, F.mapProfile(l.profile, () => ps));
        return S.update(ss, ss.actived, layer);
      }),
    [],
  );

  const insertProfile = useCallback(
    (i, pt) => setProfile(A.insert(profile, i, pt)),
    [setProfile, profile],
  );

  useEffect(() => {
    setPoint();
  }, [selection.actived]);

  return {
    // data
    ...selection,
    profile,
    point,
    // functions
    active,
    push,
    update,
    remove,
    setProfile,
    insertProfile,
    setPoint,
  };
}
