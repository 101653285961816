import { Point } from './point';
import * as F from './feature';

export interface FeatureCollection {
  type: 'FeatureCollection';
  features: F.Feature[];
}

export const empty = {
  type: 'FeatureCollection',
  features: [],
};

export const filter = (
  { features = [], ...rest }: FeatureCollection,
  f: (x: F.Feature) => boolean,
): FeatureCollection =>
  ({ ...rest, features: features.filter(f) });

export const toLatLng = (
  { features = [], ...rest }: FeatureCollection,
): FeatureCollection =>
  ({ ...rest, features: features.map(F.toLatLng) });

export const findById = (
  { features = [] }: FeatureCollection,
  id: string,
) =>
  features.find(f => F.hasId(f, id));

export const takeByReach = (fc: FeatureCollection, reach: string) =>
  filter(fc, f => F.getReach(f) === reach);

export const findByNearPoint = (fc: FeatureCollection, pt: Point) =>
  filter(fc, f => F.isNear(f, pt));
