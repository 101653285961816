import React, { useContext } from 'react';
import cx from 'classnames';
import { StageContext } from '@/components/ProfileEditor/Stage';
import { Path, Text } from '@/components/ProfileEditor/Control';
import styles from './index.module.scss';

export default function CoordinateGrid({ gridWidth = 10, gridHeight = 10 }) {
  const { viewBox } = useContext(StageContext);
  const left = viewBox.x;
  const right = viewBox.x + viewBox.width;
  const top = viewBox.y;
  const bottom = viewBox.y + viewBox.height;

  const startX = left - (left % gridWidth);
  const endX = right - (right % gridWidth);
  let gridY = [];
  let gridEmY = [];
  let axisY = [];
  for (let x = startX; x <= endX; x += gridWidth) {
    const axis = x === 0;
    const em = x % (5 * gridWidth) === 0;
    const path = (
      <g
        key={`${x} ${top}`}
        className={cx(styles.grid, { axis, em })}
      >
        <Path strokeWidth={1} d={`M ${x} ${top} L ${x} ${bottom}`} />
        {(axis | em) &&
          <Text fontSize={14} x={x} y={bottom} dx={4} dy={-4}>
            {(x / gridWidth)}
          </Text>
        }
      </g>
    );
    if (axis) {
      axisY.push(path);
    } else if (em) {
      gridEmY.push(path);
    } else {
      gridY.push(path);
    }
  }

  const startY = top - (top % gridHeight);
  const endY = bottom - (bottom % gridHeight);
  let gridX = [];
  let gridEmX = [];
  let axisX = [];
  for (let y = startY; y <= endY; y += gridHeight) {
    const axis = y === 0;
    const em = y % (5 * gridHeight) === 0;
    const path = (
      <g
        key={`${left} ${y}`}
        className={cx(styles.grid, { axis, em })}
      >
        <Path strokeWidth={1} d={`M ${left} ${y} L ${right} ${y}`} />
        {(axis | em) &&
          <Text fontSize={14} textAnchor="end" x={right} y={y} dx={-4} dy={-4}>
            {-(y / gridHeight)}
          </Text>
        }
      </g>
    );
    if (axis) {
      axisX.push(path);
    } else if (em) {
      gridEmX.push(path);
    } else {
      gridX.push(path);
    }
  }

  return (
    <g className={styles.className}>
      {gridX}
      {gridY}
      {gridEmX}
      {gridEmY}
      {axisX}
      {axisY}
    </g>
  )
}
