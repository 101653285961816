import React, { useState } from 'react';
import cx from 'classnames';
import styles from './index.module.scss';

const PADDING_TOP = 2;
const PADDING_LEFT = 4;
const OFFSET_X = 12;
const OFFSET_Y = 4;

const isSameRect = (ra, rb) =>
  ra && rb &&
  (ra.x === rb.x) &&
  (ra.y === rb.y) &&
  (ra.width === rb.width) &&
  (ra.height === rb.height)

export default function DEMPoint({
  actived = false,
  primary = false,
  data = {},
  onClick,
}) {
  const { label, x, y } = data;
  const [bbox, setBBox] = useState();

  return (
    <g
      className={cx(styles.className, { actived, primary })}
      onClick={onClick}
    >
      <circle cx={x} cy={y} r={3} />
      {bbox &&
        <rect
          className={styles.background}
          x={bbox.x - PADDING_LEFT}
          y={bbox.y - PADDING_TOP}
          width={bbox.width + 2 * PADDING_LEFT}
          height={bbox.height + 2 * PADDING_TOP}
        />
      }
      <text
        x={x}
        y={y}
        dx={OFFSET_X}
        dy={OFFSET_Y}
        ref={(node) => {
          if (!node) return;
          const newBox = node.getBBox();
          if (isSameRect(bbox, newBox)) return;
          setBBox(newBox);
        }}
      >
        {label}
      </text>
    </g>
  );
}
