import React, { useContext } from 'react';
import { StageContext } from '@/components/ProfileEditor/Stage';

export default function Text({ fontSize = 0, dx = 0, dy = 0, ...rest }) {
  const { unscale } = useContext(StageContext);

  const [fs] = unscale([fontSize, 0]);
  const [sdx, sdy] = unscale([dx, dy]);

  return (
    <text {...rest} fontSize={fs} dx={sdx} dy={sdy} />
  );
}
