import { coordsToLatLng } from './proj4';
import { Point, near } from './point';

export interface GeoPoint {
  type: 'Point';
  coordinates: Point;
}

export interface GeoLineString {
  type: 'LineString';
  coordinates: Point[];
}

export type Geometry =
  | GeoPoint
  | GeoLineString;

export const toLatLng = (geometry: Geometry) => {
  switch (geometry.type) {
    case 'LineString': {
      return {
        ...geometry,
        coordinates:
          geometry.coordinates.map((coord) => coordsToLatLng(coord)),
      };
    }
    case 'Point': {
      return {
        ...geometry,
        coordinates: coordsToLatLng(geometry.coordinates),
      };
    }
    default:
      return geometry;
  }
};

export const isNear = (geometry: Geometry, pt: Point) => {
  switch (geometry.type) {
    case 'Point': {
      return near(geometry.coordinates, pt);
    }
    default:
      return false;
  }
};
