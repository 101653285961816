import React from 'react';
import cx from 'classnames';
import styles from './index.module.scss';
import * as S from '@/types/selection';
import { Feature as F } from '@/types/geojson';
import * as L from '@/types/layer';

function ProfileLayer({
  actived = false,
  data,
  onClick,
  onLayerChange,
  onLayerRemove,
}) {
  const { profile, visible } = data;
  const id = F.getId(profile) || 'N/A';
  const classes = cx({ actived });

  return (
    <li
      className={classes}
      onClick={onClick}
    >
      {id}
      <span className={styles.action}>
        <a
          href="/"
          onClick={(evt) => {
            evt.preventDefault();
            if (typeof onLayerChange === 'function') {
              onLayerChange(evt, L.updateVisible(data, !visible));
            }
          }}
        >
          {visible ? 'hide' : 'show'}
        </a>
        <a
          href="/"
          onClick={(evt) => {
            evt.preventDefault();
            if (typeof onLayerRemove === 'function') {
              onLayerRemove(evt, data);
            }
          }}
        >
          remove
        </a>
      </span>
    </li>
  );
}

export default function ProfileLayerList({
  id,
  className,
  data = S.empty,
  onSelect,
  onLayerChange,
  onLayerRemove,
}) {
  const classes = cx('h2e-profile-layers', styles.className, className);
  const { actived } = data;

  return (
    <ol
      id={id}
      className={classes}
      onClick={(evt) => {
        if (typeof onSelect === 'function') {
          evt.stopPropagation();
          onSelect(S.NONE);
        }
      }}
    >
      {data.selections.map((l, i) =>
        <ProfileLayer
          key={i}
          actived={i === actived}
          data={l}
          onClick={(evt) => {
            if (typeof onSelect === 'function') {
              evt.stopPropagation();
              onSelect(i, l);
            }
          }}
          onLayerChange={(evt, ll) => {
            if (typeof onLayerChange === 'function') {
              evt.stopPropagation();
              onLayerChange(i, ll);
            }
          }}
          onLayerRemove={(evt, ll) => {
            if (typeof onLayerRemove === 'function') {
              evt.stopPropagation();
              onLayerRemove(i, ll);
            }
          }}
        />
      )}
    </ol>
  );
}
