export function getProjectedPoint(line = [], pt = []) {
  const [
    [x1 = 0, y1 = 0] = [],
    [x2 = 0, y2 = 0] = [],
  ] = line;
  const [px = 0, py = 0] = pt;
  const ex1 = x2 - x1;
  const ey1 = y2 - y1;
  const ex2 = px - x1;
  const ey2 = py - y1;
  const dp = ex1 * ex2 + ey1 * ey2;
  const len2 = ex1 * ex1 + ey1 * ey1;
  return [x1 + (dp * ex1) / len2, y1 + (dp * ey1) / len2];
}
