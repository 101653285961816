import {
  Children,
  cloneElement,
  useCallback,
  useRef,
} from 'react';

export default function Draggable({
  children,
  onDragStart,
  onDragMove,
  onDragEnd,
  onClick,
  ...props
}) {
  const child = Children.only(children);
  const isDragged = useRef(false);
  const handleMouseMove = useRef();
  const handleMouseUp = useRef();
  const handleMouseDown = useCallback((evt) => {
    evt.stopPropagation();
    const startX = evt.clientX;
    const startY = evt.clientY;
    handleMouseMove.current = (evt) => {
      if (!isDragged.current) {
        isDragged.current = true;
        if (typeof onDragStart === 'function') {
          onDragStart(evt);
        }
      }
      const offset = [
        evt.clientX - startX,
        evt.clientY - startY,
      ];
      if (typeof onDragMove === 'function') {
        onDragMove(evt, offset);
      }
    };
    handleMouseUp.current = (evt) => {
      if (!isDragged.current && typeof onClick === 'function') {
        onClick(evt);
      }
      if (isDragged.current && typeof onDragEnd === 'function') {
        isDragged.current = false;
        onDragEnd(evt);
      }
      window.removeEventListener('mouseup', handleMouseUp.current);
      window.removeEventListener('mousemove', handleMouseMove.current);
    }
    window.addEventListener('mousemove', handleMouseMove.current);
    window.addEventListener('mouseup', handleMouseUp.current);
  }, [onDragStart, onDragMove, onDragEnd, onClick]);

  return cloneElement(child, { ...props, onMouseDown: handleMouseDown });
}
