const GENERAL = 'MODE_GENERAL';
const PENCIL = 'MODE_PENCIL';
const ZOOM_IN = 'MODE_ZOOM_IN';
const ZOOM_OUT = 'MODE_ZOOM_OUT';
const MOVE = 'MODE_MOVE';

export const Mode = {
  GENERAL,
  PENCIL,
  ZOOM_IN,
  ZOOM_OUT,
  MOVE,
};

export const GRID_WIDTH = 50;
export const GRID_HEIGHT = 20;
export const SCALE_FACTOR = 0.75;
export const WHEEL_SCALE_FACTOR = 0.00390625; // 2^-8
