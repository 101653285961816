import React from 'react';
import cx from 'classnames';
import { Path } from '../../Control';
import styles from './index.module.scss';

function toSegments(data = []) {
  let result = [];
  for (let i = 0; i < data.length - 1; ++i) {
    result.push([data[i], data[i + 1]]);
  }
  return result;
}

export default function ProfilePath({
  id,
  className,
  data = [],
  color,
  onSegmentClick,
}) {
  if (data.length === 0) return null;
  const segs = toSegments(data);
  if (segs.length === 0) return null;

  const classes = cx('h2e-control-path', styles.className, className);
  const [[x, y]] = segs;
  let d = `M ${x} ${y} `;
  for (let i = 0; i < segs.length; ++i) {
    const [x, y] = segs[i][1];
    d += `L ${x} ${y} `;
  }

  return (
    <g id={id} className={classes}>
      <Path stroke={color} strokeWidth={1} d={d} />
      {segs.map((line, i) => {
        const [[x1, y1], [x2, y2]] = line;
        return (
          <Path
            className={styles.hitbox}
            key={i}
            strokeWidth={10}
            d={`M ${x1} ${y1} L ${x2} ${y2}`}
            onClick={(evt, pt) => {
              if (typeof onSegmentClick === 'function') {
                onSegmentClick(evt, pt, line);
              }
            }}
          />
        );
      })}
    </g>
  );
}
