import { GRID_WIDTH, GRID_HEIGHT } from './editor';

export function mapToScreen([x, y]) {
  return [GRID_WIDTH * x, -GRID_HEIGHT * y];
}

export function mapFromScreen([x, y]) {
  return [x / GRID_WIDTH, -y / GRID_HEIGHT];
}

export function sortByX(a, b) {
  return a[0] - b[0];
}

export function guardByAdjacentPoints(pts, i, pt) {
  const prevX = i === 0 ? -Infinity : pts[i - 1][0];
  const nextX = i === pts.length - 1 ? Infinity : pts[i + 1][0];
  const [x, y] = pt;
  return [x < prevX ? prevX : nextX < x ? nextX : x, y];
}

export function findById(id) {
  return function(feature) {
    const fid = feature && feature.content && feature.content.properties && feature.content.properties.id;
    return fid === id;
  }
}
