import React, { useContext, useCallback } from 'react';
import Draggable from '@/components/Layout/Draggable';
import { StageContext } from '@/components/ProfileEditor/Stage';

export default function Rect({
  x = 0,
  y = 0,
  width = 0,
  height = 0,
  strokeWidth = 0,
  onDragStart,
  onDragMove,
  onDragEnd,
  onClick,
  ...props
}) {
  const { stageFromScreen, unscale } = useContext(StageContext);
  const [w, h] = unscale([width, height]);
  const [sw] = unscale([strokeWidth, 0]);
  const offsetX = (width - w) / 2;
  const offsetY = (height - h) / 2;

  const handleDragStart = useCallback((evt) => {
    const pt = stageFromScreen([evt.clientX, evt.clientY]);
    if (typeof onDragStart === 'function') {
      onDragStart(evt, pt);
    }
  }, [onDragStart, stageFromScreen]);
  const handleDragMove = useCallback((evt, offset) => {
    const pt = stageFromScreen([evt.clientX, evt.clientY]);
    const oft = stageFromScreen(offset);
    if (typeof onDragMove === 'function') {
      onDragMove(evt, pt, oft);
    }
  }, [onDragMove, stageFromScreen]);
  const handleDragEnd = useCallback((evt) => {
    const pt = stageFromScreen([evt.clientX, evt.clientY]);
    if (typeof onDragEnd === 'function') {
      onDragEnd(evt, pt);
    }
  }, [onDragEnd, stageFromScreen]);
  const handleClick = useCallback((evt) => {
    const pt = stageFromScreen([evt.clientX, evt.clientY]);
    if (typeof onClick === 'function') {
      onClick(evt, pt);
    }
  }, [onClick, stageFromScreen]);

  return (
    <Draggable
      onDragStart={handleDragStart}
      onDragMove={handleDragMove}
      onDragEnd={handleDragEnd}
      onClick={handleClick}
    >
      <rect
        {...props}
        x={x + offsetX}
        y={y + offsetY}
        width={w}
        height={h}
        strokeWidth={sw}
      />
    </Draggable>
  );
}
