import { useMemo } from 'react';
import { FeatureCollection } from '@/types/geojson';
import data from './epsg-3826.json';

export default function useUpload() {
  return useMemo(
    () => FeatureCollection.toLatLng(data),
    [],
  );
}
