import * as F from './feature';
import { Point } from './point';

type LayerSource = 'map' | 'dem' | 'unknown';

export interface Layer {
  source: LayerSource;
  visible: boolean;
  profile: F.Feature;
}

export function initialize(f: F.Feature, source: LayerSource = 'map') {
  return {
    source,
    visible: true,
    profile: F.copy(f),
  };
}

export function getId(layer: Layer) {
  return F.getId(layer.profile);
}

export function updateProfile(layer: Layer, profile: Point[]) {
  return {
    ...layer,
    profile,
  };
}

export function updateVisible(layer: Layer, visible: boolean) {
  return {
    ...layer,
    visible,
  };
}

export function isVisible(layer: Layer) {
  return layer && layer.visible;
}
