import React from 'react';
import cx from 'classnames';
import styles from './index.module.scss';
import { Text } from '@/components/ProfileEditor/Control';
import * as PP from '@/types/profile-points';
import numeral from 'numeral';

const PADDING = 12;
const COORDINATE_FORMAT = '0.00';

export default function Coordinate({
  id,
  className,
  pt = [0, 0],
}) {
  const classes = cx('h2e-coordinate', styles.className, className);

  const [x, y] = pt;
  const [xx, yy] = PP.mapFromScreen(pt);
  const displayX = numeral(xx).format(COORDINATE_FORMAT);
  const displayY = numeral(yy).format(COORDINATE_FORMAT);

  return (
    <g id={id} className={classes}>
      <Text fontSize={12} x={x} y={y} dx={PADDING} dy={-PADDING}>
        ({displayX}, {displayY})
      </Text>
    </g>
  );
}
