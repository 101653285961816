import * as A from './array';
import * as L from './layer';

export const NONE = -1;

export const empty = {
  selections: [],
  actived: NONE,
};

export function getLayer(s, i) {
  return s.selections[i];
}

export function getActived(s) {
  return s.selections[s.actived];
}

export function listVisible(s) {
  return s.selections.filter(L.isVisible);
}

export function findLayerIndexById(s, id) {
  return s.selections.findIndex(l => L.getId(l) === id);
}

export function push(s, layer) {
  return {
    ...s,
    selections: A.insert(s.selections, s.selections.length, layer),
  };
}

export function update(s, i, layer) {
  return {
    ...s,
    selections: A.update(s.selections, i, layer),
  };
}

export function remove(s, i) {
  return {
    ...s,
    selections: A.remove(s.selections, i),
  };
}

export function active(s, actived) {
  return {
    ...s,
    actived,
  };
}
